import React, { useState, useEffect } from "react";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from "moment";

import {
  AppBar,
  UserMenu,
  MenuItemLink,
  WithPermissions,
  Layout
} from "react-admin";
import Snowflakes from 'magic-snowflakes';
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, ListItemIcon, MenuItem, ListItemText, Typography } from "@material-ui/core";
import { TimeNotationProvider } from "../components/FancyTime";
import { WSContextProvider } from "../util/WSContext";
import { Settings, People } from "@material-ui/icons";


import CallNotify from "../util/CallNotify";

import {
  LookupInfoButton,
  ContactLookupProvider,
} from "../util/ContactLookupContext";
const useStyles = makeStyles({
  avatar: {
    height: 30,
    width: 30,
  },
  grow: {
    flex: 1,
  },
});

const MyCustomIcon = ({ user = {} }) => {
  const classes = useStyles();

  return (
    <Avatar
      className={classes.avatar}
      src={user.avatar ? user.avatar : "JohnDoe.png"}
    />
  );
};

const MyUserMenu = ({ permissions: { user } = {}, children, ...props }) => {
  let swapName = null;
  if (localStorage && localStorage.getItem("swapName")) {
    swapName = localStorage.getItem("swapName");
  }
  return <UserMenu {...props} icon={<MyCustomIcon user={user} />}>
    <MenuItemLink
      to="/configuration"
      primaryText="Configuration"
      leftIcon={<Settings />}
    />
    {swapName && <div>
      <MenuItemLink onClick={(e) => {
        localStorage.setItem("prevUser", localStorage.getItem("token"))
        localStorage.setItem("token", localStorage.getItem("swapToken"));
        localStorage.removeItem("swapToken");
        localStorage.removeItem("swapName");
      }}
        to="/#"
        primaryText={swapName}
        leftIcon={<People />}
      />
    </div>
    }
  </UserMenu>
}
const MyUserMenuWithPerms = (props) => (
  <WithPermissions
    render={({ permissions }) => {
      return <MyUserMenu permissions={permissions} {...props} />;
    }}
  />
);

const MyAppBar = (props) => {
  const classes = useStyles();
  return (
    <AppBar {...props} userMenu={<MyUserMenuWithPerms />}>
      <div className={classes.grow} />
      <LookupInfoButton />
      <WithPermissions
        render={({ permissions, ...rest }) => {
          return <CallNotify permissions={permissions} {...rest} />;
        }}
      />
    </AppBar>
  );
};
const Snow = ({ children, ...props }) => {
  const matches = useMediaQuery('(min-width:600px)');
  let startYear = moment().startOf('year');
  let now = new moment();
  let snowEnd = startYear.clone().add(5, 'days')
  let snowStart = snowEnd.clone().add(11, 'months');
  let shouldSnow = now < snowEnd || now > snowStart;

  let [flake, setFlake] = useState();
  useEffect(() => {
    if (false)//(matches && shouldSnow |)
      setFlake(new Snowflakes())
    else if (flake) {
      flake.stop();
      flake.destroy();
    }

  }, [matches]);
  return children;
};

const MyLayout = (props) => (
  <Snow>
    <WSContextProvider>
      <TimeNotationProvider>
        <ContactLookupProvider>
          <Layout className="app" {...props} appBar={MyAppBar} />
        </ContactLookupProvider>
      </TimeNotationProvider>
    </WSContextProvider>
  </Snow>
);

export default MyLayout;
